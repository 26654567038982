import { MetaData, TextPage } from '@/components'
import { fetchPage, getPageBySlug, PageData } from '@/store/slices/pagesSlice'
import { initializeStore } from '@/store'
import { Layout } from '@/components'
import { NextPage } from 'next'
import CustomError from '@/helpers/CustomError'

const NextTextPage: NextPage<PageData> = props => {
  return (
    <>
      <MetaData title={props.header} />
      <Layout>
        <TextPage {...props} />
      </Layout>
    </>
  )
}

NextTextPage.getInitialProps = async ({ query }) => {
  const slug: string = query.pageSlug as string

  const reduxStore = initializeStore()
  const { dispatch, getState } = reduxStore
  await dispatch(fetchPage({ slug }))

  const response = getPageBySlug(getState(), slug)
  if (response.error) throw new CustomError('', response.error.statusCode)
  const data = response.data as PageData

  return {
    initialReduxState: reduxStore.getState(),
    ...data,
  }
}

export default NextTextPage
